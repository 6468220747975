import React from "react";
import {css, Theme} from "@emotion/react";

import {Container} from "@pg-design/grid";
import {calculateRemSize, mb, onDesktop, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {IFaqQuestion} from "../interfaces/IFaq";
import {FaqQuestion} from "./FaqQuestion";
import {FaqSchema} from "./FaqSchema";

interface IProps {
    className?: string;
    faqContent: {
        title: string;
        questions: IFaqQuestion[];
    };
    genericQuestions?: IFaqQuestion[] | null;
    titleAlign?: "start" | "center" | "inherit" | "justify" | "left" | "right";
}
const FAQ_TITLE = "Często zadawane pytania";
export const FaqSection = (props: IProps) => {
    if (!!props.faqContent.questions.length || (props.genericQuestions && !!props.genericQuestions.length)) {
        const schemaQuestions = props.faqContent.questions.concat(props.genericQuestions && !!props.genericQuestions.length ? props.genericQuestions : []);

        return (
            <Container css={[faqWrapper, mb(4)]} className={props.className}>
                <Text as="h2" css={[mb(4), onDesktop(textAlign("center"))]} align={props.titleAlign} variant="headline_3">
                    {props.faqContent.title || FAQ_TITLE}
                </Text>

                <div css={[faqListWrapper]}>
                    {props.genericQuestions &&
                        props.genericQuestions.map((faqElement) => {
                            return <FaqQuestion key={faqElement.name} {...faqElement} />;
                        })}
                    {props.faqContent.questions.map((faqElement) => {
                        return <FaqQuestion key={faqElement.name} {...faqElement} />;
                    })}

                    <FaqSchema questions={schemaQuestions} />
                </div>
            </Container>
        );
    }
    return <></>;
};

const faqWrapper = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 70rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        max-width: 90rem;
    }
`;

const faqListWrapper = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;
