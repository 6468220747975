import {IMpRegion, mpPublicApiLinks} from "@pg-mono/rp-api-routes";

import {mpApi} from "../../app/api/mp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";

export const getMarketplaceRegionListApi = mpApi.injectEndpoints({
    endpoints: (builder) => ({
        getMarketplaceRegionList: builder.query<IPaginatedResponse<IMpRegion>, void>({
            query: () => ({
                url: mpPublicApiLinks.marketplace.regions.list(),
                method: "GET"
            })
        })
    }),
    overrideExisting: false
});

export const {useGetMarketplaceRegionListQuery} = getMarketplaceRegionListApi;
