import React from "react";
import {useTheme} from "@emotion/react";

import {AccordionCore} from "./AccordionCore";
import {Content, IProps as IContentProps} from "./Content";
import {Header, IProps as IHeaderProps} from "./Header";

interface IComposition {
    Content: React.FC<IContentProps>;
    Header: React.FC<IHeaderProps>;
}

export type AccordionVariant = "dark" | "gray" | "outline" | "warm" | "white";

interface IProps {
    children: React.ReactNode;
    initiallyOpen?: boolean;
    variant?: AccordionVariant;
    onChange?: (collapsed: boolean) => void;
    className?: string;
}

export const Accordion: React.FC<IProps> & IComposition = (props) => {
    const {initiallyOpen, onChange, variant = "white", className} = props;

    const theme = useTheme();

    const variants = {
        dark: {
            background: theme.colors.secondary,
            borderRadius: "0",
            color: "#fff"
        },
        gray: {
            background: theme.colors.gray["200"]
        },
        outline: {
            background: "#fff",
            bordered: true
        },
        warm: {
            background: theme.colors.background["100"]
        },
        white: {
            background: "#fff"
        }
    };

    return (
        <AccordionCore className={className} {...variants[variant]} initiallyOpen={initiallyOpen} onChange={onChange}>
            {props.children}
        </AccordionCore>
    );
};

Accordion.Content = Content;
Accordion.Header = Header;
